import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedUiModule } from '@shared-ui';
import { FooterComponent } from './components/footer/footer.component';
import { InformationsStructureComponent } from './components/forms/informations-structure/informations-structure.component';
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { MaterialModule } from './modules/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AffectationIntervenantLectureSeuleModalComponent } from './components/modals/affectation-intervenant-lecture-seule-modal/affectation-intervenant-lecture-seule-modal.component';
import { NavItemComponent } from '@shared-evaluateur/components/nav-item/nav-item.component';
import { ProjetPropositionComiteSyntheseComponent } from '@shared-evaluateur/components/projet-proposition-comite-synthese/projet-proposition-comite-synthese.component';
import { ProjetNotationJuryNationalComponent } from '@shared-evaluateur/components/projet-notation-jury-national/projet-notation-jury-national.component';
import { ProjetInstructionNotationComponent } from '@shared-evaluateur/components/projet-instruction-notation/projet-instruction-notation.component';
import { ProjetInstructionNotationCreationComponent } from '@shared-evaluateur/components/projet-instruction-notation-creation/projet-instruction-notation-creation.component';
import { ProjetSyntheseNotationBaseComponent } from '@shared-evaluateur/components/projet-synthese-notation-base/projet-synthese-notation-base.component';
import { SyntheseNotationCardComponent } from '@shared-evaluateur/components/projet-synthese-notation-base/synthese-notation-card/synthese-notation-card.component';
import { ProjetNotationInterventionPiTagsComponent } from './components/projet-notation-intervention-pi-tags/projet-notation-intervention-pi-tags.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    NgbPopoverModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
    SharedUiModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    SpinnerOverlayComponent,
    SidenavComponent,
    InformationsStructureComponent,
    AffectationIntervenantLectureSeuleModalComponent,
    NavItemComponent,
    ProjetPropositionComiteSyntheseComponent,
    ProjetSyntheseNotationBaseComponent,
    SyntheseNotationCardComponent,
    ProjetInstructionNotationComponent,
    ProjetInstructionNotationCreationComponent,
    ProjetNotationJuryNationalComponent,
    ProjetNotationInterventionPiTagsComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    HeaderComponent,
    FooterComponent,
    SpinnerOverlayComponent,
    SidenavComponent,
    InformationsStructureComponent,
    AffectationIntervenantLectureSeuleModalComponent,
    ProjetPropositionComiteSyntheseComponent,
    ProjetSyntheseNotationBaseComponent,
    SyntheseNotationCardComponent,
    ProjetInstructionNotationComponent,
    ProjetInstructionNotationCreationComponent,
    ProjetNotationJuryNationalComponent,
    ProjetNotationInterventionPiTagsComponent,
  ],
})
export class SharedModule {}
