// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnumAuthConfigurationType } from '@shared-ui';

export const SITE_ADMIN = 'admin';
export const SITE_EVAL = 'eval';
export const IS_LOCAL = false;
export const MOCK_TOKEN =
  'eyJ0eXAiOiJKV1QiLCJraWQiOiJoZE02cXlOR1RObXUyQURDMVlncW80cmpUL0U9IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiQWxsYzBYRjhPREozM21GVmZCVUJDQSIsInN1YiI6IjRkOGU0MjBmLTE3MTYtNGQ3Ny05MTlmLWI5YTM0M2ZkOWRjMCIsImF1ZGl0VHJhY2tpbmdJZCI6ImRmMThiMzRiLTJlYWEtNDliZi1hMDM1LWM5YWFiYzIzOTExMC03NjQ2NyIsInN1Ym5hbWUiOiI0ZDhlNDIwZi0xNzE2LTRkNzctOTE5Zi1iOWEzNDNmZDlkYzAiLCJpc3MiOiJodHRwczovL21zdC5tY2ItbnByZC5jbG91ZC5icGlmcmFuY2UuZnI6NDQzL2FtL29hdXRoMi9yZWFsbXMvcm9vdC9yZWFsbXMvbWNiIiwidG9rZW5OYW1lIjoiaWRfdG9rZW4iLCJnaXZlbl9uYW1lIjoiYWRlbWUiLCJub25jZSI6IjY5YWI4YzM5NmY5ZWI0YjM2ODc1ZTNiNWNlODJmNjg0OGFReURoYlR6Iiwic2lkIjoiZlN1bnJ3OUdxUFdjbEJPL1NWdHNDenNBclB2aE9Jc3FXa2M3YTZjU3p4cz0iLCJhdWQiOiJwaWN4ZWwtc3BhIiwiY19oYXNoIjoiZndQTkR0c0FUZXo4WGJZenM1bWFWZyIsImFjciI6ImxvZ2luc2ltcGxlIiwib3JnLmZvcmdlcm9jay5vcGVuaWRjb25uZWN0Lm9wcyI6ImhWODdYVmc5b1AxSzRuRzRxaVRDTzYwSm1qOCIsInNfaGFzaCI6Ill6MnFTcHNlRldMMDZTb3h3NVFZUkEiLCJhenAiOiJwaWN4ZWwtc3BhIiwiYXV0aF90aW1lIjoxNzMxNTA3MjQ1LCJuYW1lIjoiYWRlbWUgTWJzIiwicmVhbG0iOiIvbWNiIiwiZXhwIjoxNzMxNTExMTI2LCJ0b2tlblR5cGUiOiJKV1RUb2tlbiIsImlhdCI6MTczMTUwNzUyNiwiZmFtaWx5X25hbWUiOiJNYnMiLCJlbWFpbCI6Im1lbWJyZS1hZGVtZS1tYnNAeW9wbWFpbC5jb20ifQ.v2OScKjTBpr4f0SSDgv_uWHKElwhsiSWyardRNrQfEOO50CN1GBJwuPWuYrR0FoomAh49x2u4U24AxTFwu2KTUAomhwewtVGz8CM40wk3iQkzfZo--kyjZnVWZsl0BROJ5ZoN_hivDd3-dNDXw7KhvM_04z7dg7CQi64CxrwNW0izenEpUSTJWZ8T21lKxWUhbxnUx7htHPetPfzIx_BR4naNbUUMJKpvxYxSPTiPHoDNM4EoHdOhr9vyrCjFciGkEr6KsvXhYicZh1reOZzAht9Ipt_LD2Yzsjq1xzZGx3Q3CUxTuiUnsl34I4eSz5soieZzwXNeUNBe6Xguch9ZQ';

const LOCAL_API_BASE_URL = 'http://localhost:8083/api/';
const ISAM_DEV_API_BASE_URL = 'https://apiv2-nprd.cloud.bpifrance.fr/dev/pxl/interne/sig-dev/api/';
const MCB_DEV_API_BASE_URL = 'https://apiv2-nprd.cloud.bpifrance.fr/dev/pxl/sig-dev/api/';
const LAMBDA_DEV_API_BASE_URL = 'https://pxl.dev.api-at.cloud.bpifrance.fr/main/v1/';

const getBaseUrlAccordingToIdp: (isLambdaRequest?: boolean) => string = (isLambdaRequest?: boolean) => {
  if (isLambdaRequest) {
    return (window as any)['env']['apiUrl'] || (IS_LOCAL ? LOCAL_API_BASE_URL : LAMBDA_DEV_API_BASE_URL);
  }
  const idp: EnumAuthConfigurationType = (localStorage.getItem('idp') as EnumAuthConfigurationType) || EnumAuthConfigurationType.MCB;
  const isIsam: boolean = idp === EnumAuthConfigurationType.ISAM;
  const isamBaseApiUrl: string = (window as any)['env']['isamBaseApiUrl'] || (IS_LOCAL ? LOCAL_API_BASE_URL : ISAM_DEV_API_BASE_URL);
  const mcbBaseApiUrl: string = (window as any)['env']['mcbBaseApiUrl'] || (IS_LOCAL ? LOCAL_API_BASE_URL : MCB_DEV_API_BASE_URL);
  return isIsam ? isamBaseApiUrl : mcbBaseApiUrl;
};

export const environment = {
  production: false,
  name: (window as any)['env']['name'] || 'local',
  isLocal: (window as any)['env']['isLocal'] || IS_LOCAL,
  mockToken: (window as any)['env']['mockToken'] || MOCK_TOKEN,
  apiUrlLambda: getBaseUrlAccordingToIdp(true),
  apiUrl: getBaseUrlAccordingToIdp(),
  apiAdminUrl: `${getBaseUrlAccordingToIdp()}admin/`,
  apiAdminUrlV2: `${getBaseUrlAccordingToIdp()}v2/admin/`,
  apiNotif: 'http://localhost:8082/api/',
  urlGDC: (window as any)['env']['urlGDC'] || 'https://gdcoad-ihm.dev.apps.web.bpifrance.fr/gdc/#/dc',
  isam: {
    stsServer: (window as any)['env']['stsServer'] || 'https://authmoa.web.bpifrance.fr',
    authWellknownEndpoint:
      (window as any)['env']['authWellknownEndpoint'] || 'https://authmoa.web.bpifrance.fr/mga/sps/oauth/oauth20/metadata/OIDCP_All-Prof',
    clientId: (window as any)['env']['clientId'] || '5lI2Qjmm4WslAIOT65Y5',
    scopes: 'openid profile email api offline_access',
  },
  mcb: {
    enabled: (window as any)['env']['mcbEnabled'] || true,
    stsServer: (window as any)['env']['stsServerMCB'] || 'https://mst.mcb-nprd.cloud.bpifrance.fr:443/am/oauth2/mcb',
    authWellknownEndpoint:
      (window as any)['env']['authWellknownEndpointMCB'] ||
      'https://mst.mcb-nprd.cloud.bpifrance.fr/am/oauth2/realms/root/realms/mcb/.well-known/openid-configuration',
    clientId: (window as any)['env']['clientIdMCB'] || 'picxel-spa',
    scopes: 'openid profile email',
    profil:
      (window as any)['env']['profilMCB'] ||
      'https://mst.mcb-nprd.cloud.bpifrance.fr/am/oauth2/realms/root/realms/mcb/authorize?client_id=picxel-spa&redirect_uri=https://mst.mcb-nprd.cloud.bpifrance.fr/profile&scope=openid%20profile%20email&response_type=code',
    createAccountUrl:
      (window as any)['env']['createAccountUrl'] || 'https://mst.mcb-nprd.cloud.bpifrance.fr/am/oauth2/realms/root/realms/mcb/authorize',
  },
  docPublicBaseUrl: (window as any)['env']['docPublicBaseUrl'] || 'https://evaluateur.dev.pxl-nprd.cloud.bpifrance.fr/document-public/',
  docLogosPublicBaseUrl: (window as any)['env']['docLogosPublicBaseUrl'] || 'https://candidat.dev.pxl-nprd.cloud.bpifrance.fr/logos/',
  espaceCandidat: (window as any)['env']['espaceCandidat'] || 'https://candidat.dev.pxl-nprd.cloud.bpifrance.fr',
  site: (window as any)['env']['site'] || SITE_EVAL,
  stub: false,
  stubPrograms: false,
  stubAaps: false,
  stubProjets: false,
  stubAuth: false,
  stubDoc: false,
  stubUtilisateur: false,
  urlQlik: 'https://master-staging.qls-nprd.cloud.bpifrance.fr/saml/hub/stream/73023daa-a9fb-450a-a2d6-ffbfe50325ec',
  urlKLF: 'https://kyc.moa.bpi.flaminem.com/customer-file/file/',
  apiSearchUrl:
    (window as any)['env']['apiSearchUrl'] ||
    (IS_LOCAL ? 'http://localhost:8086/' : 'https://apiv2-nprd.cloud.bpifrance.fr/dev/pxl/search-dev/'),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
