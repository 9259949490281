<div class="d-flex flex-wrap">
  <div *ngIf="!hideNotationAverage" class="ds-input-group w-100">
    <label for="moyenne-notations" class="ds-input-group__label">Moyenne des notations</label>
    <span class="ds-input-group__input">
      <input
        libDecimalNumber
        fractionDigits="2"
        id="moyenne-notations"
        name="moyenne-notations"
        type="text"
        class="ds-input-group__input"
        [ngModel]="notationsAverage"
        placeholder="Moyenne des notations"
        disabled
      />
    </span>
  </div>

  <form [formGroup]="propositionForm" class="d-flex flex-wrap w-100">
    <div class="ds-input-group w-100" *ngIf="isPhaseInstruction && aap?.ilab">
      <label class="ds-input-group__label">Proposition grand prix <lib-asterisk></lib-asterisk></label>
      <div>
        <input type="radio" name="grandPrix" id="Oui" class="ds-radio-group__radio-input" [value]="true" formControlName="grandPrix" />
        <label for="Oui" class="ds-radio-group__radio-label">Oui</label>

        <input type="radio" name="grandPrix" id="Non" class="ds-radio-group__radio-input" [value]="false" formControlName="grandPrix" />
        <label for="Non" class="ds-radio-group__radio-label">Non</label>
      </div>
    </div>

    <div class="ds-input-group__select ds-input-group__select--with-icon w-50 pe-2">
      <label for="proposition-comite" class="ds-input-group__label">Proposition au comité</label>
      <select id="proposition-comite" name="proposition-comite" formControlName="avis">
        <option [ngValue]="null" selected disabled hidden>Sélectionnez une option</option>
        <option [ngValue]="undefined" selected disabled hidden>Sélectionnez une option</option>
        <option *ngFor="let item of EnumDecisionComite.all()" [value]="item">{{ EnumDecisionComite.toString(item) }}</option>
      </select>
    </div>

    <div
      id="date-picker-envoi"
      class="date-time__date ds-input-group ds-input-group--date single-field-container w-50 ps-2"
      [class.ds-input-group--error]="
        propositionForm.get('dateEnvoiValidationEtat').invalid && propositionForm.get('dateEnvoiValidationEtat').touched
      "
    >
      <label class="ds-input-group__label">Date d'envoi pour validation par l’Etat</label>
      <span class="ds-input-group__input">
        <input
          matInput
          [matDatepicker]="closeDatePicker"
          formControlName="dateEnvoiValidationEtat"
          class="ds-input-group__input"
          placeholder="JJ/MM/AAAA"
        />
        <mat-datepicker-toggle matSuffix [for]="closeDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #closeDatePicker></mat-datepicker>
      </span>
    </div>

    <ng-container *ngIf="phase !== EnumPhaseComite.AUDITION">
      <div class="ds-input-group ds-input-group__select ds-input-group__select--with-icon w-50 pe-2">
        <label for="dispositif" class="ds-input-group__label">Fléchage budgétaire</label>
        <select id="dispositif" name="dispositif" formControlName="dispositif">
          <option [ngValue]="null" selected disabled hidden>Sélectionner</option>
          <option [ngValue]="undefined" selected disabled hidden>Sélectionner</option>
          <option *ngFor="let item of dispositifOptions" [ngValue]="item.code">{{ item.nom }}</option>
        </select>
      </div>

      <div class="ds-input-group ds-input-group__select ds-input-group__select--with-icon w-50 ps-2">
        <label for="convention" class="ds-input-group__label">Convention</label>
        <select id="convention" name="convention" formControlName="convention">
          <option [ngValue]="null" selected disabled hidden>Convention</option>
          <option [ngValue]="undefined" selected disabled hidden>Convention</option>
          <option *ngFor="let item of EnumConvention.all()" [value]="EnumConvention.toString(item)">
            {{ EnumConvention.toString(item) }}
          </option>
        </select>
      </div>
    </ng-container>

    <div
      class="ds-input-group w-100"
      [class.ds-input-group--error]="
        !propositionForm.controls['syntheseADestinationEtat'].valid && propositionForm.controls['syntheseADestinationEtat'].touched
      "
    >
      <div class="ds-input-group__label">Synthèse de l'organisme {{ organisme?.nom }} à destination de l'Etat</div>
      <textarea
        id="synthese_area"
        name="syntheseADestinationEtat"
        rows="5"
        col="0"
        maxlength="2500"
        class="ds-input-group__textarea"
        formControlName="syntheseADestinationEtat"
        type="text"
        placeholder="Synthèse de l'organisme {{ organisme?.nom }} à destination de l'Etat"
        #syntheseADestinationEtatInput
      ></textarea>
      <div class="form-input__length-hint" [class.form-input__length-hint--full]="syntheseADestinationEtatInput.value?.length === 2500">
        <span>{{ syntheseADestinationEtatInput.value?.length || 0 }}/2500</span>
      </div>
    </div>

    <div *ngIf="withRapportInstruction" class="w-100 mt-3">
      <lib-document-async-uploader
        #documentAsyncUploaderComponent
        [(documents)]="rapportInstructionDocuments"
        title="Rapport d'instruction"
        [projetId]="projet.id"
        [canUserWrite]="canUserWrite"
        [populateDocFields]="getDocFieldsCompleter()"
        [acceptedDocuments]="ACCEPTED_DOCUMENTS"
        uploadButtonText="Déposer un rapport d'instruction"
      >
      </lib-document-async-uploader>
    </div>

    <div class="d-flex justify-content-end w-100 mt-3" *ngIf="canUserWrite">
      <button id="cancel-synthese-btn" type="button" class="ds-btn ds-btn--secondary me-2" (click)="resetSynthese()">Annuler</button>
      <button id="save-synthese-btn" type="submit" class="ds-btn ds-btn--primary" (click)="saveSynthese()">Enregistrer</button>
    </div>
  </form>
</div>
